import React from "react"
import List from "../../components/List"
import Page from "../../components/Page"
import Link from "../../components/Link"
import Paragraph from "../../components/Paragraph"
import LegalList from "../../components/LegalList"

const PrivacyPolicy = () => (
  <Page title={"Privacy policy"}>
    <LegalList
      description={
        <Paragraph>
          ChangeHub is developed and owned by madewithlove BV with registered
          office at Sluisstraat 79/03.01, 3000 Leuven (Belgium) and registered
          with the CBE under number 0898.833.969. This privacy policy will
          explain how our organization uses the personal data we collect from
          you when you use this website.
        </Paragraph>
      }
      sections={[
        {
          title: "What data do we collect",
          children: (
            <>
              <Paragraph>ChangeHub collects the following data:</Paragraph>
              <List>
                <li>
                  Personal identification information (Name, email address,
                  phone number etc.)
                </li>
                <li>
                  Information we receive from third party integrations such as
                  GitHub Pull Request descriptions, tag and release.
                </li>
              </List>
            </>
          ),
        },
        {
          title: "How do we collect your data",
          children: (
            <>
              <Paragraph>
                You directly provide ChangeHub with most of the data we collect.
                We collect data and process data when you:
              </Paragraph>
              <List>
                <li>
                  Register online or place an order for any of our products or
                  services.
                </li>
                <li>
                  Voluntarily complete a customer survey or provide feedback on
                  any of our message boards or via email.
                </li>
                <li>Use or view our website via your browser’s cookies.</li>
              </List>
            </>
          ),
        },
        {
          title: "How will we use your data?",
          children: (
            <>
              <Paragraph>
                ChangeHub collects your data so that we can:
              </Paragraph>
              <List>
                <li>Process your order and manage your account.</li>
                <li>Email you with updates to our product.</li>
                <li>Deliver the essential services of our product.</li>
              </List>
              <Paragraph>
                When ChangeHub processes your order, it may send your data to,
                and also use the resulting information from, credit reference
                agencies to prevent fraudulent purchases.
              </Paragraph>
            </>
          ),
        },
        {
          title: "How do we store your data?",
          children: (
            <>
              <Paragraph>
                ChangeHub securely stores your data at various data centers in
                Europe.
              </Paragraph>
            </>
          ),
        },
        {
          title: "Marketing",
          children: (
            <>
              <Paragraph>
                Madewithlove BV would like to send you information about
                products and services of ours that we think you might like.
              </Paragraph>

              <Paragraph>
                If you have agreed to receive marketing, you may always opt out
                at a later date.
              </Paragraph>

              <Paragraph>
                You have the right at any time to stop madewithlove BV from
                contacting you for marketing purposes.
              </Paragraph>

              <Paragraph>
                If you no longer wish to be contacted for marketing purposes,
                please email us at{" "}
                <Link to={"mailto:marketing@madewithlove.com"} external={true}>
                  marketing@madewithlove.com
                </Link>
                .
              </Paragraph>
            </>
          ),
        },
        {
          title: "What are your data protection rights?",
          children: (
            <>
              <Paragraph>
                ChangeHub would like to make sure you are fully aware of all of
                your data protection rights. Every user is entitled to the
                following:
              </Paragraph>
              <List>
                <li>
                  <strong>The right to access</strong> - You have the right to
                  request ChangeHub for copies of your personal data. We may
                  charge you a small fee for this service.
                </li>

                <li>
                  <strong>The right to rectification</strong> - You have the
                  right to request that ChangeHub correct any information you
                  believe is inaccurate. You also have the right to request
                  ChangeHub to complete the information you believe is
                  incomplete.
                </li>

                <li>
                  <strong>The right to erasure</strong> - You have the right to
                  request that ChangeHub erase your personal data, under certain
                  conditions.
                </li>

                <li>
                  <strong>The right to restrict processing</strong> - You have
                  the right to request that ChangeHub restrict the processing of
                  your personal data, under certain conditions.
                </li>

                <li>
                  <strong>The right to object to processing</strong> - You have
                  the right to object to ChangeHub’s processing of your personal
                  data, under certain conditions.
                </li>

                <li>
                  <strong>The right to data portability</strong> - You have the
                  right to request that ChangeHub transfer the data that we have
                  collected to another organization, or directly to you, under
                  certain conditions.
                </li>
              </List>

              <Paragraph>
                If you make a request, we have one month to respond to you. If
                you would like to exercise any of these rights, please contact
                us at our email:{" "}
                <Link to={"mailto:changehub@madewithlove.com"} external>
                  changehub@madewithlove.com
                </Link>
                <br />
                Call us at:{" "}
                <Link to={"tel:+3278480480"} external>
                  32 78 480 480
                </Link>
                <br />
                Or write to us: madewithlove BV, Sluisstraat 79/03.01, 3000
                Leuven, Belgium
              </Paragraph>
            </>
          ),
        },
        {
          title: "What are cookies?",
          children: (
            <>
              <Paragraph>
                Cookies are text files placed on your computer to collect
                standard Internet log information and visitor behavior
                information. When you visit our websites, we may collect
                information from you automatically through cookies or similar
                technology.
              </Paragraph>
              <Paragraph>
                For further information, visit{" "}
                <Link to={"https://allaboutcookies.org/"} external>
                  allaboutcookies.org/
                </Link>
              </Paragraph>
            </>
          ),
        },
        {
          title: "How do we use cookies?",
          children: (
            <>
              <Paragraph>
                ChangeHub uses cookies in a range of ways to improve your
                experience on our website, including:
              </Paragraph>
              <List>
                <li>Keeping you signed in</li>
                <li>Understanding how you use our website</li>
              </List>
            </>
          ),
        },
        {
          title: "What types of cookies do we use?",
          children: (
            <>
              <Paragraph>
                There are a number of different types of cookies, however, our
                website uses:
              </Paragraph>
              <List>
                <li>
                  Functionality – Our Company uses these cookies so that we
                  recognize you on our website and remember your previously
                  selected preferences. These could include what language you
                  prefer and location you are in. A mix of first-party and
                  third-party cookies are used.
                </li>
              </List>
            </>
          ),
        },
        {
          title: "How to manage your cookies",
          children: (
            <Paragraph>
              You can set your browser not to accept cookies, and the above
              website tells you how to remove cookies from your browser.
              However, in a few cases, some of our website features may not
              function as a result.
            </Paragraph>
          ),
        },
        {
          title: "Privacy policies of other websites",
          children: (
            <Paragraph>
              The ChangeHub website contains links to other websites. Our
              privacy policy applies only to our website, so if you click on a
              link to another website, you should read their privacy policy.
            </Paragraph>
          ),
        },
        {
          title: "Changes to our privacy policy",
          children: (
            <Paragraph>
              ChangeHub keeps its privacy policy under regular review and places
              any updates on this web page. This privacy policy was last updated
              on 15 May 2020.
            </Paragraph>
          ),
        },
        {
          title: "How to contact us",
          children: (
            <>
              <Paragraph>
                If you have any questions about Our Company’s privacy policy,
                the data we hold on you, or you would like to exercise one of
                your data protection rights, please do not hesitate to contact
                us.
              </Paragraph>

              <Paragraph>
                Email us at:{" "}
                <Link to={"mailto:changehub@madewithlove.com"} external>
                  changehub@madewithlove.com
                </Link>
                <br />
                Call us at:{" "}
                <Link to={"tel:+3278480480"} external>
                  32 78 480 480
                </Link>
                <br />
                Or write to us: madewithlove BV, Sluisstraat 79/03.01, 3000
                Leuven, Belgium
              </Paragraph>
            </>
          ),
        },
        {
          title: "How to contact the appropriate authority",
          children: (
            <Paragraph>
              Should you wish to report a complaint or if you feel that
              ChangeHub has not addressed your concern in a satisfactory manner,
              you may contact the Data Protection Authority in Belgium by
              following{" "}
              <Link
                to={
                  "https://www.gegevensbeschermingsautoriteit.be/verzoek-klacht-indienen"
                }
                external
              >
                this link
              </Link>{" "}
              (only available in Dutch and French).
            </Paragraph>
          ),
        },
      ]}
    />
  </Page>
)

export default PrivacyPolicy
