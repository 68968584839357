import styled from "styled-components"

const Paragraph = styled.p`
  line-height: 1.7rem;
  margin-bottom: 1rem;

  & + p {
    margin-top: 1.4rem;
  }
`

Paragraph.propTypes = {}

export default Paragraph
